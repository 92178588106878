body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
    color: #2e2e2e;
}
.container{
  background-color: #f1f1f1;
  min-height: 100vh;
}
.containerPd{
  padding: 0 2rem 1rem 2rem;
}
.empty_div {
  padding: 1.4rem 0;
}
button {
  cursor: pointer;
}
.none{
  visibility: hidden;
}
.hide {
  display: none;
}
.show {
  display: block;
}
.printIcon {
  height: 0.9rem;
  margin-bottom: -0.15rem;
}
.printIcon.sageGreen path {
  fill: #6A994E;
}
.printIcon.white path {
  fill: white;
}
.printIcon.blue path {
  fill: #009dbe;
}
.lightGreenIcon path {
  fill: #03b20f81;
}
.lightGreyIcon path {
  fill: #b1b1b1;
}
.fontXS {
  font-size: 0.7rem;
}
.fontS {
  font-size: 0.8rem;
  /* font-weight: 420; */
}
.fontM {
  font-size: 1.1vw;
  /* font-weight: 550; */
}
.fontL {
  font-size: 1.5vw;
  /* font-weight: 450; */
}
.fontXL {
  font-size: 2rem;
}
.fontXXL {
  font-size: 3.4vw;
}
.bold{
  font-weight: 600;
}
.bolder{
  font-weight: bolder;
}
.lgreen{
  color: #ceffb6;
}
.green {
  color: #009688;
}
.sageGreen{
  color: #6A994E;
}
.leafyGreen {
  color: #03b20f;
}
.red {
  color: #e70101;
}
.orange{
  color :#FF6F02;
}
.purple{
  color: #895af6;
}
.blue{
  color: #008ec6;
}
.white{
  color: #fff;
}
.darkGrey{
  color: #5d5d5d;;
}
.brightYellow{
  color: #66ff33;
}
.refreshIcon{
  width: 1.6vw;
  vertical-align: bottom;
  margin-right: 6px;
}
.pointer {
  cursor: pointer;
}
.not_allowed {
  cursor: not-allowed;
}
.default {
  cursor: default;
}
.labelGrey {
  color: #999;
}
.labelGrey path {
  fill: #999!important;
}
.labelGrey button{
  color: #999;
  border: 1px solid #999;
}
.greenIcon path{
  fill: #009688;
}
.breadCrump {
  text-decoration: underline;
  cursor: pointer;
}
.navIcon{
  height: 22px;
  margin-right: 22px;
  margin-top: -5px;
}
.icon22{
  height: 22px;
  width: 22px;
  margin-bottom: -5px;
}
.visibility_hidden{
  visibility: hidden;
}
.dropdown_dummy_div {
  width: 100%;
  height: 100%;
  position: fixed;
  /* z-index: 1; */
  /* background-color: #f00; */
  left: 0;
  top: 0;
}
.boxShadow {
  box-shadow: 0px 0px 2px #009688;
}
.redBrd{
  border: 2px solid red!important;
}
.greenBrd{
  border-bottom: 2px solid #009d8b;
}
.greyBrdBtm{
  border-bottom: 1px solid #ccc;
}
.flex{
  display: flex;
  align-items: center;
}
.flexbox{
  display: flex;
  align-items: flex-start;
}
.flexend{
  display: flex;
  align-items: flex-end;
}
.flexStretch{
  display: flex;
  align-items: stretch;
}
.flex-space-between{
  justify-content: space-between;
}
.flexColumn{
  display:flex;
  flex-direction:column;
}
.flexNoWrap{
  flex-wrap: nowrap;
}
.blueColor{
  color: #219ebc;
}
.bgLightRed{
  background-color: #ffd8d8;
}
.bgLightGrey{
  background-color: #fafff3;
}
.bgLightGreen{
  background-color: #efffd7;
}
.bgLightBlue{
  background-color: #ebf3ff;
}
.alignImage{
  vertical-align: middle;
}
.alignImageBtm{
  vertical-align: bottom;
}
.grey{
  color: #6c757d;
}
.relative{
  position: relative;
}
.absolute{
  position: absolute;
}
.textUnderline{
  text-decoration: underline;
}
.textCenter{
  text-align: center;
}
.textRight{
  text-align: right;
}
.textLeft{
  text-align: left;
}
.textCapitalize{
  text-transform: capitalize;
}
.grid{
  display: grid;
}
.alignItemsStart{
  align-items: start;
}
.gridCenter{
  display: grid;
  align-items: center;
}
.gridEnd{
  display: grid;
  align-items: end;
}
.btn{
  border-radius: 8px;
  padding: 0.4rem 0.8rem;
  font-size: 0.9vw;
  border: none;
}
.btnSwitcherBlue{
  background: #219ebc;
  color: white;
}
.btnSwitcherGreen{
  background: #009688;
  color: white;
}
.separator{
  border-bottom: 1px dashed #cfcfcf;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
}

.iconM {
  width: 2.4vw;
  height: 1.6vw;
}
/* ----------   blink btn   ---------- */

.aPulse {
  --animdur: 2.4s;
  --animn: glowing;
  will-change: transform;
  font-size: 9.8px;

  -webkit-animation: glowing 1500ms infinite;
  -moz-animation: glowing 1500ms infinite;
  -o-animation: glowing 1500ms infinite;
  animation: glowing 1500ms infinite;
}
[data-animation] {
  animation: var(--animn, none) var(--animdur, 0s) var(--animtf, linear)
    var(--animic, infinite);
}
/* KEYFRAMES */
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes glowing {
  0% {
    background-color: #00796b;
    box-shadow: 0 0 2px #00ac98;
  }
  50% {
    background-color: #15d7c0;
    box-shadow: 0 0 8px #34ebd5;
  }
  100% {
    background-color: #00796b;
    box-shadow: 0 0 2px #00ac98;
  }
}

@media screen and (max-width: 1290px) {
  .fontS{
    font-size: 10px;
    /* font-weight: 400; */
  }
  .font_default_res, .fontM{
    font-size: 1.2vw;
  }
}