.navbar {
  display: inline-block;
  position: fixed;
  z-index: 15;
  /* background-color: #009688; */
}
.closeIcon { 
  width: 2.1rem;
  margin-bottom: 0.8rem;
  left: -100%;
  position: fixed;
  background-color: #009688;
  border-radius: 8px;
  padding: 10px 20px;
}
.closeIcon.active{
  left: 21vw;
  transition: 600ms;
}
.iconDrawer {
  padding: 0.6rem 0.4rem;
  width: 2.1rem; 
}
.iconBack {
  width: 1.2rem;
  padding: 0.4rem 0.2rem;
  margin-bottom: 10px;
}
.navMenu {
  overflow: auto;
  padding: 0.6rem 0.8rem;
  background-color: #009688;
  position: fixed;
  z-index: 15;
  height: 100vh;
  width: 22vw;
  left: -100%;
  transition: 600ms;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.navMenu.active {
  left: 0;
  transition: 600ms;
}
.navMenu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.nav-item {
  /* border: 0.2px solid white; */
  padding: 1rem 0.6rem 1.4rem 0.6rem;
  font-size: 1.1rem;
  border-bottom: 1px solid #ccc;
}
.navMenu ul div {
  text-decoration: none;
  color: white;
}
.nav-item div span {
  color: white;
}
.dummyDiv {
  background-color: rgba(0, 0, 0, 0.36);
  color: white;
  position: fixed;
  z-index: 13;
  top: -100%;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: opacity 600ms ease-out;
  cursor: pointer;
}
.dummyDiv.active {
  opacity: 1;
  top: 0;
  transition: opacity 600ms ease-in;
}
.logoutBtn {
  margin: 2.5rem 0.4rem 3.8rem;
  padding: 0.4rem 0.6rem;
  font-size: 1rem;
  text-transform: uppercase;
  /* border-radius: 5px;
  border: 1px solid #c17d7d; */
  border-radius: 20px;
  border: 2px solid #ffffff;
  background-color: #009688;
  color: white;
  font-weight: bold;
}
.logoutIcon {
  margin-bottom: -0.4rem;
  height: 25px;
}
.userDetail {
  color: whitesmoke;
  text-align: center;
  margin: 1rem 0;
}
.capital {
  text-transform: capitalize;
  margin-bottom: 0.4rem;
}